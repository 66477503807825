import type {LoaderOptions} from '@googlemaps/js-api-loader';
import {Loader} from '@googlemaps/js-api-loader';

export const googleMapsIsLoaded = (): boolean => {
  return (
    window['google'] !== undefined && window['google']['maps'] !== undefined
  );
};

export const loadGoogleMaps = async (clientId: string) => {
  if (googleMapsIsLoaded()) {
    return window['google']['maps'];
  }

  const loader = new Loader({
    version: 'quarterly',
    client: clientId,
    language: 'en',
  } as LoaderOptions);

  await loader.load().catch((err: any) => console.error(err));

  if (googleMapsIsLoaded()) {
    return window['google']['maps'];
  }
};
