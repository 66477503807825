export const userHasLocationEnabled = (): boolean => {
  if (!window.navigator?.geolocation) {
    return false;
  }

  let locationEnabled = true;

  if (window.navigator.permissions) {
    window.navigator.permissions
      .query({name: 'geolocation'})
      .then(function (result) {
        locationEnabled =
          result.state === 'granted' || result.state === 'prompt';
      });
  }

  return locationEnabled;
};

export const requestUserLocation = async (
  timeout: number
): Promise<void | Promise<GeolocationCoordinates | undefined>> => {
  return await new Promise<GeolocationCoordinates | undefined>(
    (resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords);
        },
        () => {
          reject();
        },
        {timeout}
      );
    }
  ).catch(() => {
    // Do nothing. This allows us to avoid unnecessary try / catches below and instead return early after async await.
  });
};
